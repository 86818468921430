@keyframes slideLeftToPosition {
	0% {
		transform: translateX(-400px);
	}
	80% {
		transform: translateY(0);
	}
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(-5px);
	}
	20% {
		transform: translateX(5px);
	}
	30% {
		transform: translateX(-5px);
	}
	40% {
		transform: translateX(5px);
	}
	50% {
		transform: translateX(-5px);
	}
	60% {
		transform: translateX(5px);
	}
	70% {
		transform: translateX(-5px);
	}
	80% {
		transform: translateX(5px);
	}
	90% {
		transform: translateX(-5px);
	}
	1000% {
		transform: translateX(0px);
	}
}

@keyframes fade {
	0% {
		opacity: 1;
	}

	20% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@keyframes imageGoBlackAndWhite {
	0% {
		filter: grayscale(0);
	}
	20% {
		filter: grayscale(1);
	}
}

.slideLeftToPosition {
	animation-name: slideLeftToPosition, shake, fade, imageGoBlackAndWhite;
	animation-duration: 1000ms, 100ms, 3000ms, 6000ms;
	animation-iteration-count: 1, 10, 1;
	animation-timing-function: ease-out, ease-in-out, ease-in, ease-in-out;
	animation-delay: 0s, 1000ms, 3000ms, 1000ms;
	animation-play-state: running;
}

@keyframes blinkRed {
	0% {
		background: #f92626;
	}
	40% {
		background: #ffffff;
	}
	60% {
		background: #ffffff;
	}
	100% {
		background: #f92626;
	}
}

.blinkRed {
	animation-name: blinkRed;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: ease-in-out;
	animation-delay: 0s;
	animation-play-state: running;
}
