* {
	font-family: "Inter";
	padding: 0;
	margin: 0;
	user-select: none;
}

html,
body {
	top: 0;
	left: 0;
	margin-top: 0;
	margin-left: 0;
}
