@keyframes simulationTransition {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	10% {
		opacity: 1;
	}

	80% {
		transform: scale(8);
		opacity: 0;
	}

	100% {
		transform: scale(8);
		opacity: 0;
	}
}

.simulationTransition {
	/* other styles for the SVG image */
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-name: simulationTransition;
	animation-timing-function: ease-in-out;
	/*  animation-delay: 10s; */
	animation-play-state: running;
	animation-fill-mode: forwards;
}

@keyframes videoTransition {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	80% {
		transform: scale(1);
		opacity: 1;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.videoTransition {
	/* other styles for the SVG image */
	animation-duration: 1s;
	animation-iteration-count: 1;
	animation-name: videoTransition;
	animation-timing-function: ease-in-out;
	/*  animation-delay: 10s; */
	animation-play-state: running;
	animation-fill-mode: both;
}
