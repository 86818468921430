@keyframes bouncing {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(-20px);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes slideRightToPosition {
	0% {
		transform: translateX(400px);
	}
	80% {
		transform: translateY(0);
	}
}

@keyframes shake {
	0% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(-5px);
	}
	20% {
		transform: translateX(5px);
	}
	30% {
		transform: translateX(-5px);
	}
	40% {
		transform: translateX(5px);
	}
	50% {
		transform: translateX(-5px);
	}
	60% {
		transform: translateX(5px);
	}
	70% {
		transform: translateX(-5px);
	}
	80% {
		transform: translateX(5px);
	}
	90% {
		transform: translateX(-5px);
	}
	1000% {
		transform: translateX(0px);
	}
}

.bouncing-complaint {
	/* other styles for the SVG image */
	animation-duration: 2s;
	animation-iteration-count: infinite;
	animation-name: bouncing;
	animation-timing-function: ease-in-out;
	animation-delay: 0.5s;
	animation-play-state: running;
}

.slideRightToPosition {
	animation-name: slideRightToPosition, shake, bouncing;
	animation-duration: 1000ms, 200ms, 2s;
	animation-iteration-count: 1, 2, infinite;
	animation-timing-function: ease-out, ease-in-out, ease-in-out;
	animation-delay: 0s, 1000ms, 1.5s;
	animation-play-state: running;
}

@keyframes scale {
	0% {
		transform: scale(0);
	}
	80% {
		transform: scale(1);
	}
}

.scaleWithBounce {
	animation-name: scale, bouncing;
	animation-duration: 1000ms, 2s;
	animation-iteration-count: 1, infinite;
	animation-timing-function: ease-out, ease-in-out;
	animation-delay: 0s, 1000ms;
	animation-play-state: running;
}

@keyframes scale {
	0% {
		transform: scale(0);
	}
	80% {
		transform: scale(1);
	}
}

.scale {
	animation-name: scale;
	animation-duration: 1000ms;
	animation-iteration-count: 1;
	animation-timing-function: ease-out;
	animation-delay: 0s;
	animation-play-state: running;
}
